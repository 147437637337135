<template lang="pug">
  button.app-button(
    :disabled="disabled"
    :style="{backgroundColor: colorStyle, fontSize, borderColor: border}"
    :class="[classList, 'button-text', ...classTypes , {'app-button_hide-shadow': hideShadow, 'error-field': error}, colorClass]"
  )
    span.app-button__wrapper
      span.app-button__before-icon(v-if="$slots.before")
        slot(name="before")
      slot(v-if="!loading")
      span.app-button__after-icon(v-if="$slots.after")
        slot(name="after")
      base-preloader(v-if="loading").app-button__preloader
    span.error-message(v-if="errorMessages") {{ errorMessages }}
    slot(name="additional")
</template>

<script>
import { COLORS } from '@/app/admin/modules/course_managment/core/cm-const'

const BUTTON_TYPE = {
  BLOCK: 'block',
  DASHED: 'dashed',
  ICON_SMALL: 'iconSmall',
  ICON: 'icon',
  ICON_LARGE: 'iconLarge',
  ROUNDED: 'rounded',
  TEXT: 'text',
  SMALL: 'small',
  TRANSPARENT: 'transparent',
  OUTLINED: 'outlined',
  HIGHLIGHTED: 'highlighted'
}

export default {
  name: 'crmButton',

  props: {
    loading: Boolean,
    error: Boolean,
    errorMessages: String,
    label: String,
    color: String,
    classList: String,
    disabled: Boolean,
    hideShadow: Boolean,
    [BUTTON_TYPE.BLOCK]: Boolean,
    [BUTTON_TYPE.SMALL]: Boolean,
    [BUTTON_TYPE.DASHED]: Boolean,
    [BUTTON_TYPE.ICON_SMALL]: Boolean,
    [BUTTON_TYPE.ICON]: Boolean,
    [BUTTON_TYPE.ICON_LARGE]: Boolean,
    [BUTTON_TYPE.ROUNDED]: Boolean,
    [BUTTON_TYPE.TEXT]: Boolean,
    [BUTTON_TYPE.OUTLINED]: Boolean,
    fontSize: String,
    v2: Boolean,
    [BUTTON_TYPE.TRANSPARENT]: Boolean,
    border: String,
    

  },

  computed: {
    colorClass() {
      if (Object.keys(COLORS).includes(this.color))
        if (this[BUTTON_TYPE.TEXT]) return `text-${this.color}`
        else return `color-${this.color}`
      return ''
    },

    colorStyle() {
      if (Object.keys(COLORS).includes(this.color)) return ``
      return this.color + ' !important'
    },

    classTypes() {
      let classes = []
      let typeMap = {
        [BUTTON_TYPE.BLOCK]: 'app-button_block',
        [BUTTON_TYPE.SMALL]: 'app-button_small',
        [BUTTON_TYPE.DASHED]: 'app-button_dashed',
        [BUTTON_TYPE.ICON_SMALL]: 'app-button_icon_small',
        [BUTTON_TYPE.ICON]: 'app-button_icon',
        [BUTTON_TYPE.ICON_LARGE]: 'app-button_icon_large',
        [BUTTON_TYPE.ROUNDED]: 'app-button_rounded',
        [BUTTON_TYPE.TEXT]: 'app-button_text',
        [BUTTON_TYPE.TRANSPARENT]: 'app-button_transparent',
        [BUTTON_TYPE.OUTLINED]: 'app-button_outlined',
        v2: 'app-button_v2',
      }

      for (let type in typeMap) {
        if (this[type]) classes.push(typeMap[type])
      }
      if(this.border) classes.push('app-button_border')
      return classes
    }
  },

  components: {
    BasePreloader: () => import('@/components/global/BasePreloader.vue')
  }
}
</script>

<style lang="scss">
.app-button {
  position: relative;
  padding: 8px;
  min-width: 80px;
  outline: none;
  border-radius: 4px;
  color: #000000;
  font-weight: 500;
  font-size: 12px;
  transition: all 0.15s ease-in-out;
  cursor: pointer;

  &__before-icon {
    display: inline-block;
    margin-right: 3px;
    vertical-align: middle;
  }
  &__after-icon {
    display: inline-block;
    margin-left: 3px;
    vertical-align: middle;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: 0 2px 8px 0;
    content: "";
    opacity: 0.45;
  }

  &:hover {
    opacity: 0.9;
    transition: all 0.15s ease-in-out;
  }

  &__wrapper {
    text-align: center;
    letter-spacing: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
  }

  &__preloader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    color: $label-color;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  &_block {
    width: 100%;
  }

  &_dashed {
    padding: 15px;
    border: dashed 1px #B7CDFE;
    border-radius: 8px;
    background-color: #ECF2FF;

    &[disabled] {
      border: dashed 1px #BBC4CA;
    }
  }

  &_hide-shadow,
  &_icon,
  &_rounded,
  &_text,
  &_dashed {
    &:after {
      box-shadow: unset;
    }
  }

  &_small {
    padding: 1px;
    min-width: 40px;
    min-height: 20px;
    font-size: 8px;
  }

  &_icon {
    padding: 0;
    min-width: auto;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: #F4F8F9;
    color: #888A95;
    font-size: 13px;

    &_small {
      width: 25px;
      height: 25px;
      font-size: 12px;
    }

    &_large {
      font-size: 20px;
    }

    .material-icons {
      font-size: inherit;
    }

    .app-button__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }

  &_rounded {
    border-radius: 50%;
  }

  &_transparent {
    background-color: transparent !important;
  }

  &_border {
    border-width: 1px;
    border-style: solid;
  }

  &_outlined {
    border: 1px solid currentColor;
    background-color: transparent !important;
    &.color-primary {
      color: $primary-color !important;
    }
  }

  &[disabled].app-button_transparent {
    background-color: transparent !important;
  }

  &[disabled] {
    background-color: #F4F7F9 !important;
    opacity: 0.6;
    cursor: not-allowed;

    .app-button__wrapper {
      color: #BBC4CA !important;
              user-select: none;

      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;

      span,
      i {
        color: inherit !important;
      }
    }
  }

  &.error-field {
    border-color: #FF5252;
    background-color: #FFE9E9;
  }

  .error-message {
    right: 0;
    left: auto;
    margin-top: 20px;
  }

  &.color-primary::after {
    box-shadow: 0 1px 4px 0 rgba(78, 127, 237, 0.41);
  }

  &.color-negative {
    background-color: $negative;
    color: #FFFFFF !important;
  }

  &.color-error::after {
    box-shadow: 0 1px 4px 0 rgba(237, 78, 78, 0.41);
  }
  &_v2 {
    border-radius: 4px;
    font-weight: 700;
    &::after {
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }
  }
}

</style>
